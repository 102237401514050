.app__resume {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.resume__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 2rem 0 0rem;
}

.resume__img {
  border-radius: 0.5rem;
  border: 1px solid var(--primary-color);
  max-width: 100%;
  object-fit: contain;

  &:hover {
    box-shadow: 0 0 25px var(--secondary-color);
  }
}


.app__resume-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 2rem 0 0rem;

  .app__resume-button {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: '#fff';
    color: #000;
    font-weight: 800;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;
    font-size: .8rem;
    text-decoration: none;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    &:hover {
      background-color: var(--secondary-color);
      color: #fff;
    }

    @media screen and (min-width: 2000px) {
      padding: 1rem 2rem;
      border-radius: 0.85rem;
    }
  }
}